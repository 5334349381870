import * as data from './config.json';

const operatorLowercased = data.default?.BRANCH_NAME.replace('-dev', '').replace('dev-', '').toLowerCase();

export default {
  OPERATOR_LOWERCASED: operatorLowercased,
  OPERATOR_PASCALED: `${operatorLowercased.charAt(0).toUpperCase()}${operatorLowercased.slice(1)}`,

  // This will override data above
  ...data.default || {},
  ENV_PREFIX: data.default?.BRANCH_NAME.includes('dev') ? 'dev' : 'prod',
  SIGNALS_ARRAY: JSON.parse(data.default.SIGNALS_ARRAY),
  FEEDBACK_REASONS: JSON.parse(data.default.FEEDBACK_REASONS),
  PROCEDURE_FAILURES: JSON.parse(data.default.PROCEDURE_FAILURES),
  WORKFLOW_SIGNALS_ARRAY: JSON.parse(data.default.WORKFLOW_SIGNALS_ARRAY),
};
