import { DateTime } from 'luxon';

const currentDate = (format: string = 'DD'): string => DateTime.local().toFormat(format);
const formatLocalDate = (date: string, fromFormat: string = 'yyyy-MM-dd', toFormat: string = 'MM/dd/yy'): string => DateTime.fromFormat(date, fromFormat).toLocal().toFormat(toFormat)
const toLocalDate = (date: string, fromFormat: string = 'yyyy-MM-dd'): Date => DateTime.fromFormat(date, fromFormat).toLocal().toJSDate();
const convertUTCDateToLocalDate = (date: string): Date => {
  const newDate = new Date(date);
  const localDate = new Date(newDate.getTime() + (-newDate.getTimezoneOffset() * 60 * 1000));

  return localDate;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  currentDate,
  formatLocalDate,
  toLocalDate,
  convertUTCDateToLocalDate
};
