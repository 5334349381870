
import { Component, Vue } from 'vue-property-decorator';
import accountModule from '@/store/modules/accountModule';
import { getAccessToken, isTokenExpired, refreshAccessToken, isLoggedIn } from '@/lib/auth';
import workflowModule from './store/modules/workflowModule';
import { getComponent } from '@/utils/helpers';
import isOnline from 'is-online';

@Component({
  components: {
    TasqMainBanner: () => getComponent('base/TasqMainBanner'),
  },
})
export default class App extends Vue {

  isOnlineApp = navigator.onLine
  async beforeCreate() {
    this.isOnlineApp = await isOnline()
    if (isLoggedIn() && this.isOnlineApp) {
      await workflowModule.getUserProfile({
        username: accountModule.user.email.toLowerCase(),
        useExisting: false,
      });
      if (workflowModule.user && !workflowModule.user.isVerified) {
        this.$router.push({ name: 'VerifyInfo' });
      }
    }
  }

  async created() {
    window.addEventListener('online', this.onlineMode);
    window.addEventListener('offline', this.offlineMode);
    this.isOnlineApp = await isOnline()
    if (!this.$route.meta!.skipAuth && getAccessToken() && this.isOnlineApp) {
        await refreshAccessToken();

    }
  }



  beforeDestroy() {
    // eslint-disable-next-line no-restricted-globals
    window.removeEventListener('online', this.onlineMode);
    // eslint-disable-next-line no-restricted-globals
    window.removeEventListener('offline', this.offlineMode);
  }

  async onlineMode() {
    this.isOnlineApp = true;
  }

  async offlineMode() {
    this.isOnlineApp = false;
  }
}
