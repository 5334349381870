
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TasqButton extends Vue {
  @Prop({ type: String, default: 'primary' }) type!: string;

  @Prop({ type: String, default: 'medium' }) size!: string;

  @Prop({ type: String }) icon!: string;

  @Prop({ type: Boolean, default: false }) loading!: boolean;

  @Prop({ type: Boolean, default: false }) disabled!: boolean;

  @Prop({ type: String }) tooltipText!: string;

  @Prop({ type: Boolean, default: true }) iconOnLeft!: boolean;

  get iconSize(): string {
    let sizeInPixels: string = '24';
    if (this.size === 'extraSmall') {
      sizeInPixels = '12';
    }
    if (this.size === 'mini') {
      sizeInPixels = '14';
    }
    if (this.size === 'small') {
      sizeInPixels = '16';
    }
    if (this.size === 'medium') {
      sizeInPixels = '20';
    }
    return sizeInPixels;
  }

  handleClick(event) {
    this.$emit('click', event);
  }
}
