
import {
 Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import TasqTabNav from './TabNav.vue';

@Component({
  components: {
    TasqTabNav,
  },
})
export default class TasqTabs extends Vue {
  @Prop({ type: String }) value!: string;

  @Prop({ type: Boolean, default: false }) tabsAsTags!: boolean;

  @Prop({ type: Boolean, default: false }) tabsAsBorder!: boolean;

  @Prop({ type: String, default: 'large' }) size!: string;

  panes: any[] = []

  currentName: string = this.value

  provide() {
    return {
      currentName: this.currentName,
    };
  }

  addPanes(pane) {
    const index = this.$slots.default.filter((item) =>
      // @ts-ignore
      // eslint-disable-next-line implicit-arrow-linebreak
       item.elm.nodeType === 1 && /\btab-pane\b/.test(item.elm.className)).indexOf(pane.$vnode);

    this.panes.splice(index, 0, pane);
  }

  removePanes(item) {
    const { panes } = this;
    const index = panes.indexOf(item);
    if (index > -1) {
      panes.splice(index, 1);
    }
  }

  handleTabClick(tab, tabName, event) {
    this.setCurrentName(tabName);
    this.$emit('tab-click', tab, event);
    if (this.$refs.nav) {
      // @ts-ignore
      this.$refs.nav.setActiveTab();
    }
  }

  setCurrentName(activeTabName) {
    this.currentName = activeTabName;
    this.$emit('input', activeTabName);
  }

  created() {
    if (!this.currentName) {
      this.setCurrentName(this.value);
    }
  }

  @Watch('value')
  onValueChange(newVal) {
    this.setCurrentName(newVal);
  }
}
