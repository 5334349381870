const mapDefaultWorkTicketTasqs = (t, userEmail = ''): any => {
  const workTicket = {
    id: t.PredictionID || t.ID || t.NodeID || t.wellName,
    workflowTasqId: t.WorkflowTaskID || null,
    nodeID: t.NodeID,
    wellName: t.NodeID || t.PadName,
    padName: t.PadName,
    area: t.Area,
    route: t.Route,
    initialUserName: t.Assignee != null ? t.Assignee.initial_assignment : '',
    closed: t.Closed,
    detailedSteps: t.DetailedSteps,
    details: t.Details,
    files: t.Files || [],
    assignee: t.Assignee != null ? t.Assignee.initial_assignement : '',
    createdBy: t.CreatedBy || t.Username,
    jobType: t.JobType,
    level: t.Level,
    manuallyAssigned: t.ManuallyAssigned === 'True',
    operator: t.Operator,
    predictionType: t.PredictionType,
    jobCategory: t.PredictionType,
    priority: t.Priority,
    requestCompletionDate: t.RequestCompletionDate,
    role: t.Role,
    requestDetails: t.RequestDetails,
    reason: t.TasqStatus && t.TasqStatus.Reason ? t.TasqStatus.Reason : '',
    status: t.TasqStatus && t.TasqStatus.Status ? t.TasqStatus.Status : 'Open',
    statusEndDate: t.TasqStatus && t.TasqStatus.StatusEndDate ? t.TasqStatus.StatusEndDate : null,
    statusStartDate: t.TasqStatus && t.TasqStatus.StatusStartDate ? t.TasqStatus.StatusStartDate : null,
    ticketStartDate: t.TasqStatus && t.TasqStatus.TicketStartDate ? t.TasqStatus.TicketStartDate : null,
    // eslint-disable-next-line no-nested-ternary
    ticketDueDate: t.TasqStatus && t.TasqStatus.TicketDueDate ? t.TasqStatus.TicketDueDate : t.TasqStatus && t.TasqStatus.TicketStartDate ? t.TasqStatus.TicketStartDate : null,
    tasqType: t.TasqType,
    username: t.Username,
    team: t.Team,
    time: t.time,
    priorityLevel: 0,
  };

  workTicket.priorityLevel = [
    'Lowest',
    'Low',
    'Medium',
    'High',
    'Highest',
  ].findIndex((p) => p === workTicket.priority);

  // @ts-ignore
  return workTicket;
};


export {
  // eslint-disable-next-line import/prefer-default-export
  mapDefaultWorkTicketTasqs,
};
