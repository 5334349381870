
import {
 Component, Vue, Watch,
} from 'vue-property-decorator';

const typeMap = {
  success: 'checkCircleOutline',
  gpt: 'checkCircleOutline',
  info: 'informationOutline',
  warning: 'alertCircleOutline',
  error: 'closeCircleOutline',
};
export const TasqAlertState = {
    instance: undefined as any,
};

@Component
export default class TasqAlert extends Vue {
  visible: boolean = false;

  title: string = '';

  message: string = '';

  duration: number = 3000;

  type: string = 'info';

  closed: boolean = false;

  timer: any = null;

  get typeClass() {
    return this.type ? typeMap[this.type] : '';
  }

  destroyElement() {
    this.$el.removeEventListener('transitionend', this.destroyElement);
    this.$el.parentNode!.removeChild(this.$el);
    this.$destroy();
    TasqAlertState.instance = undefined;
  }

  close() {
    this.closed = true;
  }

  clearTimer() {
    clearTimeout(this.timer);
  }

  startTimer() {
    if (this.duration > 0) {
      this.timer = setTimeout(() => {
        if (!this.closed) {
          this.close();
        }
      }, this.duration);
    }
  }

  restartTimer() {
    clearTimeout(this.timer);
    if (this.duration > 0) {
      this.timer = setTimeout(() => {
        if (!this.closed) {
          this.close();
        }
      }, this.duration);
    }
  }

  mounted() {
    this.startTimer();
  }

  @Watch('closed')
  onClosedChange(newVal) {
    if (newVal) {
      this.visible = false;
      this.$el.addEventListener('transitionend', this.destroyElement);
    }
  }
}
