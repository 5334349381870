
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class TasqAccordion extends Vue {
  @Prop({ type: String, default: "" }) title!: string;

  @Prop({ type: String, default: "" }) subtitle!: string;

  @Prop({ type: Boolean, default: false }) alwaysOpen!: boolean;

  @Prop({ type: Boolean, default: false }) defaultOpen!: boolean;

  @Prop({ type: Boolean, default: false }) reverseIcon!: boolean;

  isOpen: boolean = false;

  created(){
    this.isOpen = this.defaultOpen;
  }

  toggleAccordion() {
    this.isOpen = !this.isOpen;
    this.$emit('toggle-accordion')
  }

  @Watch('defaultOpen')
  onUpdateDefaultOpen(data){
    console.log(data);
    this.isOpen = this.defaultOpen;
  }
}
