
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TasqTabPane extends Vue {
  @Prop({ type: String }) label!: string;

  @Prop({ type: String }) name!: string;

  @Prop({ type: String }) activeTab!: any;

  get active(): boolean {
    // @ts-ignore
    return this.activeTab === this.name;
  }

  get paneName(): string {
    return this.name;
  }

  isActive() {
    return this.activeTab === this.name;
  }

  mounted() {
    // @ts-ignore
    this.$parent.addPanes(this);
  }

  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    // @ts-ignore
    this.$parent.removePanes(this);
  }
}
