import store from '@/store';
import { VuexModule, Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import {
  getConditionByValue,
  getConditionValueByValue,
  getValueModifierByValue,
  getOperationalModifierByValue,
  getAssignToByValue,
} from '@/utils/workflowHelpers';
import { getConfigEnv, isValidJsonString, jsonParse } from '@/utils/helpers';
import { getNameByEmail } from '@/utils/users';
import Workflow from '@/interfaces/workflows/Workflow';
import workflowApolloClient from '@/lib/appsync/workflow';
import linearApolloClient from '@/lib/appsync/linear';
import getAllWorkflows from '@/graphql/workflow/queries/getAllWorkflows.graphql';
import getNestedOperatorAssetsCall from '@/graphql/workflow/queries/getNestedOperatorAssets.graphql';
import getCommentHistory from '@/graphql/workflow/queries/getCommentHistory.graphql';

import getJobResponse from '@/graphql/workflow/queries/getJobResponse.graphql';
import getReportingStats from '@/graphql/deferment/queries/getReportingStats.graphql';
import TasqResponse from '@/interfaces/tasqs/TasqResponse';
import { mapTasqResponse } from '@/utils/tasqResponses';
import postShortcutBugMutation from '@/graphql/workflow/mutations/postShortcutBug.graphql';
import postLinearBugMutation from '@/graphql/workflow/mutations/postLinearBug.graphql';
import getLinearIssuesLabels from '@/graphql/workflow/queries/getLinearIssuesLabels.graphql';
import getLinearIssues from '@/graphql/workflow/queries/getLinearIssues.graphql';
import getUserDetailsQuery from '@/graphql/workflow/queries/getUserDetails.graphql';
import getOperatorShortcutStoriesQuery from '@/graphql/workflow/queries/getOperatorShortcutStories.graphql';
import getWellStatuses from '@/graphql/workflow/queries/getWellStatuses.graphql';
import getWellTodolist from '@/graphql/workflow/queries/getWellTodolist.graphql';
import getUserAssignedToWell from '@/graphql/workflow/queries/getUserAssignedToWell.graphql';
import getUserNotifications from '@/graphql/workflow/queries/getUserNotifications.graphql';
import getLatestDeferrmentValueForWell from '@/graphql/workflow/queries/getLatestDeferrmentValueForWell.graphql';
import getAreasForUser from '@/graphql/workflow/queries/getAreasForUser.graphql';
import getWorkflow from '@/graphql/workflow/queries/getWorkflow.graphql';
import searchWellsByTerms from '@/graphql/workflow/queries/searchWellsByTerms.graphql';
import postWorkflow from '@/graphql/workflow/mutations/postWorkflow.graphql';
import deleteWorkflow from '@/graphql/workflow/mutations/deleteWorkflow.graphql';
import postDeleteUser from '@/graphql/workflow/mutations/postDeleteUser.graphql';
import updateUserActiveStatus from '@/graphql/workflow/mutations/updateUserActiveStatus.graphql';
import updateWorkflowQuery from '@/graphql/workflow/mutations/updateWorkflow.graphql';
import postUserNotificationSettingsMutation from '@/graphql/workflow/mutations/postUserNotificationSettings.graphql';
import postTodolistMutation from '@/graphql/workflow/mutations/postTodolist.graphql';
import updateUserNotificationStatus from '@/graphql/workflow/mutations/updateUserNotificationStatus.graphql';
import listTeamsQuery from '@/graphql/workflow/queries/listTeams.graphql';
import fetchKnownAttributes from '@/graphql/workflow/queries/fetchKnownAttributes.graphql';
import postKnownAttribute from '@/graphql/workflow/mutations/postKnownAttribute.graphql';
import putKnownAttribute from '@/graphql/workflow/mutations/putKnownAttribute.graphql';
import accountModule from '@/store/modules/accountModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import isOnline from 'is-online';
import localforage from '@/lib/localForage';
import userPreferenceDB from '@/lib/userPreference';
import { convertUTCDateToLocalDate } from '@/utils/dates';
import Vue from 'vue'
import { DateTime } from 'luxon';

const mapWorkflow = (item) => {
  /* eslint-disable max-len */

  const conditions = [] as any;

  item.Settings.conditions.forEach((c, idx) => {
    let connector = true;
    const recurring = Object.prototype.hasOwnProperty.call(c, 'reoccurring_condition');
    if (item.Settings.conditions.length >= 2 && c.type === 'condition') {
      connector = (item.Settings.conditions[idx - 1] || {}).value === 'AND' ?? false;
    }
    if (c.type === 'condition') {
      conditions.push({
        condition: {
          text: (getConditionByValue(c.condition_group_definition.data_feed) || {}).key,
          value: c.condition_group_definition.data_feed,
        },
        conditionValue: {
          text: (getConditionValueByValue(c.condition_group_definition.source) || {}).text,
          value: c.condition_group_definition.source,
        },
        valueModifier: {
          // eslint-disable-next-line
          text: (getValueModifierByValue(c.condition_group_definition.conditional_operator) || {}).text,
          value: c.condition_group_definition.conditional_operator,
        },
        operationalModifier: {
          // eslint-disable-next-line
          text: (getOperationalModifierByValue(c.condition_group_definition.operational_modifier) || {}).text,
          value:
            c.condition_group_definition.operational_modifier == ''
              ? undefined
              : c.condition_group_definition.operational_modifier,
        },
        value: recurring ? c.reoccurring_condition.reoccurring : c.condition_group_definition.value,
        howLong: recurring ? c.reoccurring_condition.duration : c.condition_group_definition.window.split(' hours')[0],
        editable: false,
        secondSectionEditable: false,
        showActions: false,
        connector,
        recurring,
        ...(recurring && {
          recurring_data: {
            times: c.reoccurring_condition.reoccurring,
            duration: c.reoccurring_condition.duration,
            durationValue: c.reoccurring_condition.rate,
          },
        }),
      });
    }
  });
  let resultToText = item.Settings.assign_to.individual;
  if (item.Settings.assign_to.team || item.Settings.assign_to.role) {
    resultToText = getAssignToByValue(item.Settings.assign_to.team || item.Settings.assign_to.role)?.text;
  }

  let triggerText = '';
  conditions.forEach((condition, i) => {
    if ((condition.condition || {}).value === 'STATE_OF_WELL') {
      const wellStateText = condition.recurring
        ? `current well state is <span style="font-weight: 500;">${
            (condition.conditionValue || {}).text
          }</span> and has been ${(condition.conditionValue || {}).text}
         <span style="font-weight: 600;">${(condition.recurring_data || {}).times} time(s)</span> out of
         <span style="font-weight: 600;">${(condition.recurring_data || {}).duration} ${
            (condition.recurring_data || {}).durationValue
          }(s)</span>`
        : `current well state is <span style="font-weight: 500;">${(condition.conditionValue || {}).text}</span>`;
      triggerText += `${i <= 0 ? '' : condition.connector ? ' and ' : ' or '} ${wellStateText}`;
    } else {
      // eslint-disable-next-line
      triggerText += `${i <= 0 ? '' : condition.connector ? 'and' : 'or'}  <span style="font-weight: 600;">${
        condition.conditionValue.text || ''
      }</span>
    <span style="font-weight: 600;">${
      condition.operationalModifier.text === 'Value' || !condition.operationalModifier.value
        ? ''
        : condition.operationalModifier.text
    }</span>
          ${condition.valueModifier.text === 'Greater than' || condition.valueModifier.text === 'Less than' ? 'is' : ''}
      <span style="font-weight: 600;">${condition.valueModifier.text}</span>
      <span style="font-weight: 600;">${
        condition.valueModifier.value === 'DECREASES_AT' || condition.valueModifier.value === 'INCREASES_AT'
          ? `${condition.value}%`
          : condition.value || ''
      }</span> for <span style="font-weight: 600;">${condition.howLong} hours</span>
          `;
    }
  });

  const result = {
    to: {
      text: resultToText,
      value: item.Settings.assign_to.team || item.Settings.assign_to.individual || item.Settings.assign_to.role,
    },
    assignToType: item.Settings.assign_to.workflow_type,
    source: Array.isArray(item.Settings.well_source) ? item.Settings.well_source : [],
  };

  const workflowText = `If ${triggerText.toLowerCase()} for ${!result.source.length ? 'all wells' : result.source}
        then assign a tasq to <b>${getNameByEmail(result.to.text)}</b>`;

  return {
    ...item,
    active: true,
    conditions,
    result,
    id: item.WorkflowDetailsID,
    createdAt: item.time,
    modifiedAt: item.modification_date,
    triggeredCount: item.WorkflowCount,
    username: getNameByEmail(item.Username.toLowerCase()),
    workflowText,
  };
  /* eslint-enable */
};

@Module({
  dynamic: true,
  namespaced: true,
  name: 'workflowModule',
  store,
})
class WorkflowModule extends VuexModule {
  workflows: Workflow[] = [];

  wellHistory: any[] = [];

  teams: any[] = [];

  user: any = {};

  wellResponseData: any = null;

  isLoadingHistory = false;

  isTimezonePosted = false;

  defermentStats: any = null;

  downtimeByReason: any = null;

  defermentCategoryStats: any = null;

  activeWorkflow: Workflow | null = null;

  v2ResponseData: any = null;

  reportingStats: any = null;

  nestedOperatorAssetsOutput: any = [];

  wellHistoryEvents: any = [];

  wellsStatus: any = [];

  wellsAlarms: any = [];


  userNotifications: any = [];

  userUnReadNotificationCount: any = 0;

  get statusByWellName() {
    return (nodeID: string): any =>
      [...this.wellsStatus].find((t) => t.nodeID?.toLowerCase() === nodeID?.toLowerCase());
  }


  get alarmByWellName() {
    return (nodeID: string): any =>
      [...this.wellsAlarms].find((t) => t.node_name?.toLowerCase() === nodeID?.toLowerCase());
  }

  @Mutation
  setNestedOperatorAssetsOutput({ data }): void {
    this.nestedOperatorAssetsOutput = data;
  }

  @Mutation
  setWorkflows(data): void {
    this.workflows = data;
  }



  @Mutation
  setIsTimezonePosted(data): void {
    this.isTimezonePosted = data;
  }

  @Mutation
  setWellsStatus(data): void {
    this.wellsStatus = data;
  }


  @Mutation
  setWellsAlarms(data): void {
    this.wellsAlarms = data;
  }


  @Mutation
  updateWellsStatus(data): void {
    const index = this.wellsStatus.findIndex(
      tasqObject => tasqObject.nodeId == data.nodeID
    );
    if (index > -1) {
      let oldTasq = this.wellsStatus[index]
      Object.keys(oldTasq).forEach(key => oldTasq[key] = null);
      Object.entries(data).forEach(entry => Vue.set(oldTasq, entry[0], entry[1]));
    }else{
      this.wellsStatus.push(data)
    }
  }


  @Mutation
  setTeams(data): void {
    this.teams = data.sort();
  }

  @Mutation
  setIsLoadingHistory(data): void {
    this.isLoadingHistory = data;
  }

  @Mutation
  setWellResponseData(data): void {
    this.wellResponseData = data;
  }


  @Mutation
  setUserNotificationsReadStatus(notificationID = null): void {
   let notificationUnReadCounter = this.userUnReadNotificationCount
    const notifications = this.userNotifications.map(notification => {
      if(notificationID){
          if(notificationID === notification.notificationID){
            notification.markAsRead = true
            notificationUnReadCounter -= 1;

          }

       }else if(notification.markAsRead !== true){
        notification.markAsRead = true
        notificationUnReadCounter -= 1;
       }
      return {...notification}
    })
    this.userNotifications = notifications
    this.userUnReadNotificationCount = notificationUnReadCounter

  }

  @Mutation
  setUserNotifications({data,appendToExistingData = false}): void {
    let unReadNotifications = 0;
    const formattedNotifications = data.map(rawNotification => {
      const notification = JSON.parse(rawNotification)
      const payload: any = {}
      const browserTimezoneOffset = new Date().getTimezoneOffset() * 60000; // Get the browser's timezone offset in milliseconds
      const utcDatetime = new Date(notification['Time']); // Create a Date object with the provided datetime string
      const browserDatetime = new Date(utcDatetime.getTime() - browserTimezoneOffset);

      const year = browserDatetime.getFullYear(); // Get the 4-digit year
      const month = String(browserDatetime.getMonth() + 1).padStart(2, '0'); // Get the month (zero-based) and pad with leading zero if necessary
      const day = String(browserDatetime.getDate()).padStart(2, '0'); // Get the day of the month and pad with leading zero if necessary
      const hours = String(browserDatetime.getHours()).padStart(2, '0'); // Get the hours and pad with leading zero if necessary
      const minutes = String(browserDatetime.getMinutes()).padStart(2, '0'); // Get the minutes and pad with leading zero if necessary
      const seconds = String(browserDatetime.getSeconds()).padStart(2, '0'); // Get the seconds and pad with leading zero if necessary

      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`; // Construct the formatted date string

      notification['Time'] = formattedDate
      payload['type'] = notification['Type']
      payload['rawTime'] =  notification['Time']
      payload['time'] =  new Date(notification['Time'])
      payload['timestamp'] =  new Date(notification['Time']).getTime()
      payload['notificationID'] = notification['NotificationID']
      payload['predictionID'] = notification['PredictionID']
      payload['nodeName'] = notification['NodeID']
      payload['nodeLevel'] = notification['NodeID']
      payload['markAsRead'] = notification['MarkAsRead']
      payload['predictionType'] = notification['PredictionType'] || 'Tasq'
      if(notification['predictionType'] == 'Off-Target RT'){
        payload['predictionType'] = 'Real-Time'
      }

      if(payload['markAsRead'] === false){
        unReadNotifications += 1
      }

      payload['createdBy'] = notification['CreatedBy'] ? (notification['CreatedBy']) : null
      if(notification['TasqPayload']){
        const tasqPayload: any = notification['TasqPayload']
        payload['defermentValue'] = tasqPayload['MCF'] ?  Math.round(tasqPayload['MCF']) : 0
        payload['defermentUnit'] = tasqPayload['Units'] || 'mcf/d';
        payload['deferringDays'] = tasqPayload['DeferringDays'] || null;
        // @ts-ignore
        payload['defermentPerc'] = tasqPayload['DefermentPerc'] ? +parseFloat(tasqPayload['DefermentPerc'] * 100).toFixed(0) : null

      }
      // console.log(payload);
      return payload;

    })
    if(!appendToExistingData){
      this.userNotifications = formattedNotifications;
      this.userUnReadNotificationCount = unReadNotifications
    }else{
      const existingNotificationIDs = this.userNotifications.map(u => u.notificationID)
      const filterNotifications = formattedNotifications.filter(n => !existingNotificationIDs.includes(n.notificationID))
      this.userNotifications = this.userNotifications.concat(filterNotifications);
      this.userUnReadNotificationCount += filterNotifications.length
    }

  }


  @Mutation
  resetUserNotifications(): void {
    this.userNotifications = [];
  }

  @Mutation
  setV2ResponseData(data): void {
    this.v2ResponseData = data;
  }

  @Mutation
  setUserRole(role): void {
    this.user.role = role;
  }

  @Mutation
  async setUser(user) {
    const customFilters = user.custom_filters.map((customFilter) => ({
      ...customFilter,
      settings: JSON.parse(customFilter.settings),
    }));

    this.user = {
      id: user.user_id,
      username: user.username.toLowerCase(),
      email: user.user_email.toLowerCase(),
      groups: [accountModule.user.role],
      role: user.role,
      isAdmin: true,
      phoneNumber: user.user_phone_number,
      firstName: user.first_name,
      defaultFilteringDetails:
        user.custom_profile_json && isValidJsonString(user.custom_profile_json)
          ? JSON.parse(user.custom_profile_json)
          : null,
      routes: user.routes,
      isVerified: user.is_verified === false ? false : true,
      areas: user.areas,
      lastName: user.last_name,
      team:
        user.team && user.team.includes('Team_')
          ? user.team.replace('Team_', '').replace('"', '').replace('"', '')
          : user.team,
      defaultPage: user.default_page,
      adminChartAccess: user.admin_chart_access,
      userAdminAccess: user.user_admin_access,
      defaultUserFilter: user.default_user_filter,
      customFilters: customFilters,
      defaultFilters: JSON.parse(user.default_filters),
      isTestUser: user.is_test_user,
      notification_settings: user.notification_settings ? JSON.parse(user.notification_settings) : null,
    };
    tasqsListModule.setActiveFilters(this.user.defaultFilters);
  }

  @Mutation
  deleteWorkflowMutation(workflow) {
    const foundWorkflowIndex = this.workflows.findIndex((w) => w.id === workflow.id);
    if (foundWorkflowIndex !== -1) {
      this.workflows.splice(foundWorkflowIndex, 1);
    }
  }

  @Action
  async listTeams(input: any = { useExisting: false }): Promise<void> {
    try {
      const { useExisting } = input;
      if (useExisting && this.teams && this.teams.length) {
        return;
      }
      const {
        data: {
          list_teams: { Results: Teams },
        },
      }: any = await workflowApolloClient.query({
        query: listTeamsQuery,
        variables: {
          input: {
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
        fetchPolicy: 'cache-first',
      });

      this.setTeams(JSON.parse(Teams));
      await userPreferenceDB.setItem('list_teams', JSON.parse(Teams));
    } catch (error: any) {
      console.error(error.message);
      const teams: any = await userPreferenceDB.getItem('list_teams');
      if (teams && teams.length) {
        this.setTeams(teams);
      }
    }
  }

  @Action
  async getUserDetails({ username }): Promise<any> {
    const {
      data: { get_user_details: getUserDetailsResult },
    }: any = await workflowApolloClient.query({
      query: getUserDetailsQuery,
      variables: {
        operator: getConfigEnv('OPERATOR_LOWERCASED'),
        username: username.toLowerCase(),
      },
      fetchPolicy: 'network-only',
    });
    return getUserDetailsResult;
  }

  @Action
  async getOperatorShortcutStories(): Promise<any> {
    const {
      data: { get_operator_shortcut_stories: shortcutData },
    }: any = await workflowApolloClient.query({
      query: getOperatorShortcutStoriesQuery,
      variables: {
        Operator: getConfigEnv('OPERATOR_LOWERCASED'),
      },
      fetchPolicy: 'network-only',
    });
    if (shortcutData && shortcutData.Results) {
      return shortcutData.Results.map((r) => JSON.parse(r));
    }
    return [];
  }

  @Action
  async deleteUser({ username }): Promise<any> {
    const {
      data: { delete_user: getUserAreaResult },
    }: any = await workflowApolloClient.mutate({
      mutation: postDeleteUser,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          Username: username.toLowerCase(),
        },
      },
    });
  }

  @Action
  async updateUserActiveStatus({ username }): Promise<any> {
    const {
      data: { update_user_active_status: getUserAreaResult },
    }: any = await workflowApolloClient.mutate({
      mutation: updateUserActiveStatus,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          Username: username.toLowerCase(),
        },
      },
    });
  }

  @Action
  async getAreasForUser({ username }): Promise<any> {
    try {
      const {
        data: { get_areas_for_user: getUserAreaResult },
      }: any = await workflowApolloClient.query({
        query: getAreasForUser,
        variables: {
          input: {
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
            Username: username.toLowerCase(),
          },
          fetchPolicy: 'network-only',
        },
      });

      return JSON.parse(getUserAreaResult.Results);
    } catch (error: any) {
      console.error(error.message);
      return '';
    }
  }

  @Action
  async getUserProfile({ username, useExisting = false }): Promise<void> {
    try {
      if (useExisting && this.user && this.user.email) {
        return;
      }
      if (navigator.onLine) {
        const {
          data: { get_user_details: getUserDetailsResult },
        }: any = await workflowApolloClient.query({
          query: getUserDetailsQuery,
          variables: {
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
            username: username.toLowerCase(),
          },
          fetchPolicy: 'network-only',
        });
        const userAreaDetails = await this.getAreasForUser({ username: username.toLowerCase() });

        if (userAreaDetails) {
          getUserDetailsResult.areas = userAreaDetails;
        }
        userPreferenceDB.setItem(`offline-getUserDetailsResult-${username}`, getUserDetailsResult);

        if (getUserDetailsResult) {
          this.setUser(getUserDetailsResult);
        }
      } else {
        const result = await userPreferenceDB.getItem(`offline-getUserDetailsResult-${username}`);
        if (result) {
          this.setUser(result);
        }
      }
    } catch (error: any) {
      const result = await userPreferenceDB.getItem(`offline-getUserDetailsResult-${username}`);
      if (result) {
        this.setUser(result);
      }

      console.error(error.message);
    }
  }

  @Mutation
  setActiveWorkflow(data): void {
    this.activeWorkflow = data;
  }

  @Mutation
  setReportingStats(data): void {
    this.reportingStats = data;
  }

  @Mutation
  setDefermentStats(data): void {
    this.defermentStats = data;
  }

  @Mutation
  setDefermentCategoryStats(data): void {
    this.defermentCategoryStats = data;
  }

  @Mutation
  setReportingDowntimeByReason(data): void {
    if (data) {
      this.downtimeByReason = Object.keys(data)
        .map((r) => ({ key: r === 'HILINE RTD' ? 'Line Pressure' : r, value: data[r] }))
        .sort((a, b) => parseFloat(b.value) - parseFloat(a.value));

      const last = this.downtimeByReason[0];
      this.downtimeByReason.splice(0, 1);
      this.downtimeByReason.push(last);
    }

    // this.downtimeByReason = data;
  }

  @Mutation
  addWorkflowMutation(data): void {
    this.workflows.unshift(data);
  }

  @Mutation
  setWellHistory(data): void {
    this.wellHistory = data;
  }

  @Mutation
  resetWellHistoryEvents(): void {
    this.wellHistoryEvents = [];
  }

  @Mutation
  setWellHistoryEvents(data) {
    if (data && data.length && data.constructor === Array) {
      const wellHistory: any = [...data];
      let formResponseHistory = [...wellHistory.filter((h) => Object.keys(h).includes('FormsResponse'))];
      if (formResponseHistory && formResponseHistory.length) {
        formResponseHistory = formResponseHistory.map((h) => h.FormsResponse).flat();
        formResponseHistory = formResponseHistory.filter((item) => item.fields);
      }
      let otherHistory = wellHistory.filter((h) => !Object.keys(h).includes('FormsResponse') || h.Type == 'CYGNET');

      otherHistory = otherHistory.map((h) => {
        if (!Object.keys(h).includes('FormsResponse')) {
          let time = new Date(h.time)
         if(h.Type !== 'TASQ_REASSIGNMENT'){
          time =
          typeof h.time === 'string'
            ? convertUTCDateToLocalDate(h.time)
            : h.time
            ? h.time
            : convertUTCDateToLocalDate(new Date().toISOString());

         }

          h.isFormResponse = false;
          h.readableComment = '';
          h.hideEvent = false;
          h.unformattedTime = h.time ? `${new Date(h.time).toLocaleDateString()} ${new Date(h.time).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' })}` : '';

          h.formattedDateLabel = `${time.toDateString()} ${time.toLocaleTimeString()}`;
          h.time = time;
        }
        return h;
      });

      formResponseHistory = formResponseHistory
        .map((h) => {
          const time: Date = h.formTime ? new Date(h.formTime) : h.time ? new Date(h.time) : new Date();

          h.isFormResponse = true;
          h.formResponse = h;
          // eslint-disable-next-line no-nested-ternary
          h.time = time;
          h.formattedDateLabel = `${time.toDateString()} ${time.toLocaleTimeString()}`;
          h.readableComment = h.title || '';
          h.hideEvent = false;
          return h;
        })
        .filter((h) => h.readableComment);
      formResponseHistory = formResponseHistory.concat(otherHistory);
      formResponseHistory.sort((a, b) => b.time.getTime() - a.time.getTime());
      this.wellHistoryEvents = formResponseHistory;
    } else {
      this.wellHistoryEvents = [];
    }
  }

  @Action
  async getNestedOperatorAssets(): Promise<any> {
    try {
      const {
        data: { get_nested_operator_assets: GetNestedOperatorAssetsOutput },
      }: any = await workflowApolloClient.query({
        query: getNestedOperatorAssetsCall,
        variables: {
          input: {
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
      });
      const parsedResults = GetNestedOperatorAssetsOutput.Results;
      if (GetNestedOperatorAssetsOutput) {
        // JSON.parse(GetNestedOperatorAssetsOutput.Results)
        this.setNestedOperatorAssetsOutput({ data: JSON.parse(parsedResults) });
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  @Action
  async getOppPercentage() {
    const {
      data: { get_reporting_info: oppPercentage },
    }: any = await workflowApolloClient.query({
      query: getReportingStats,
      variables: {
        operator: getConfigEnv('OPERATOR_LOWERCASED'),
      },
    });

    if (oppPercentage && oppPercentage.pbar) {
      this.setReportingStats(JSON.parse(oppPercentage.pbar));
      this.setReportingDowntimeByReason(JSON.parse(oppPercentage.reasons));
      this.setDefermentStats(JSON.parse(oppPercentage.series));
      this.setDefermentCategoryStats(JSON.parse(oppPercentage.category_series));
    }
  }

  @Action
  async getWellHistory(wellName): Promise<void> {
    try {
       return;
      this.setIsLoadingHistory(true);
      const {
        data: {
          get_well_comment_history: { well_history },
        },
      }: any = await workflowApolloClient.query({
        query: getCommentHistory,
        variables: {
          node_id: wellName,
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
          GroupBy: {
            Interval: 'Day',
            Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
        fetchPolicy: 'network-only',
      });

      const result: any = JSON.parse(well_history);
      this.setWellHistory(result);
      this.setWellHistoryEvents(result);

      this.setIsLoadingHistory(false);
    } catch (error: any) {
      this.setIsLoadingHistory(false);
      console.error(error.message);
    }
  }

  @Action
  async getActiveWorkflow(workflow): Promise<void> {
    const {
      data: {
        get_workflow: {
          error,
          workflow: backendWorkflow,
          assignment_count: triggeredCount,
          actioned_percentage: actionPerc,
        },
      },
    }: any = await workflowApolloClient.query({
      query: getWorkflow,
      variables: {
        input: {
          id: workflow.id,
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
    });

    workflow = this.workflows?.find((i) => i.id === workflow.id) || {};
    if (!error && workflow) {
      workflow = Object.assign(mapWorkflow(JSON.parse(backendWorkflow)), workflow);
      if (!workflow) {
        // commit(ADD_ASSIGNMENT, assignment);
      }
      Object.assign(workflow, {
        triggeredCount,
        actionPerc,
      });

      this.setActiveWorkflow(workflow);
    }
  }

  @Action
  async searchWellsByTerms(searchQuery): Promise<any> {
    const {
      data: {
        search_wells_by_terms: { Results },
      },
    }: any = await workflowApolloClient.query({
      query: searchWellsByTerms,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_PASCALED'),
          SearchJson: searchQuery,
        },
      },
    });

    let jsonResult: any = null;

    if (Results && JSON.parse(Results)) {
      jsonResult = JSON.parse(Results);

      jsonResult = jsonResult.map((result) => ({ wellName: result, selected: false, show: false }));
    }

    return jsonResult;
  }

  @Action({
    commit: 'setWorkflows',
  })
  async getWorkflows(): Promise<Workflow[]> {
    try {
      const {
        data: {
          get_all_workflows: { workflows },
        },
      }: any = await workflowApolloClient.query({
        query: getAllWorkflows,
        variables: {
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      });

      const items = workflows.map((i) => mapWorkflow(jsonParse(i)));
      items.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

      return items;
    } catch (e) {
      console.error(`Error while fetching procedures: ${e}`);
      return [];
    }
  }

  @Action
  async addWorkflow(workflow) {
    try {
      let assignToKey = 'team';
      if (workflow.result.assignToType !== 'TEAM') {
        assignToKey = workflow.result.assignToType === 'ROLE' ? 'role' : 'individual';
      }
      const conditions = [] as any;
      // eslint-disable-next-line
      workflow.conditions.map((cond, index) => {
        const wellStateSelected = (cond.condition || {}).value === 'STATE_OF_WELL';
        if (index >= 1) {
          conditions.push({
            type: 'condition_operator',
            value: cond.connector ? 'AND' : 'OR',
          });
        }
        conditions.push({
          type: 'condition',
          condition_group_definition: {
            data_feed: cond.condition.value,
            source: cond.conditionValue,
            conditional_operator: wellStateSelected ? '' : cond.valueModifier,
            operational_modifier: wellStateSelected // eslint-disable-line
              ? ''
              : cond.operationalModifier === 'VALUE'
              ? ''
              : cond.operationalModifier,
            value: wellStateSelected ? '' : cond.value,
            window: wellStateSelected ? '' : `${cond.howLong} ${cond.windowValue || 'hours'}`,
          },
          ...(cond.recurring &&
            wellStateSelected && {
              reoccurring_condition: {
                reoccurring: cond.value,
                duration: cond.howLong,
                rate: 'DAY',
              },
            }),
        });
      });

      const workflowToSave = {
        version: '1.0',
        well_source: workflow.result.source.length ? workflow.result.source : 'ALL',
        conditions,
        assign_to: {
          workflow_type: workflow.result.assignToType,
          [assignToKey]: workflow.result.to,
        },
      };

      await workflowApolloClient.mutate({
        mutation: postWorkflow,
        variables: {
          input: {
            settings: JSON.stringify(workflowToSave),
            username: accountModule.user.email.toLowerCase(),
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
      });
      return this.getWorkflows();
    } catch (e) {
      throw new Error(e);
    }
  }

  @Action
  async updateWorkflow(workflow) {
    try {
      let assignToKey = 'team';
      if (workflow.result.assignToType !== 'TEAM') {
        assignToKey = workflow.result.assignToType === 'ROLE' ? 'role' : 'individual';
      }
      const conditions = [] as any;
      // eslint-disable-next-line
      workflow.conditions.map((cond, index) => {
        const wellStateSelected = (cond.condition || {}).value === 'STATE_OF_WELL';
        if (index >= 1) {
          conditions.push({
            type: 'condition_operator',
            value: cond.connector ? 'AND' : 'OR',
          });
        }
        conditions.push({
          type: 'condition',
          condition_group_definition: {
            data_feed: cond.condition.value,
            source: cond.conditionValue,
            conditional_operator: wellStateSelected ? '' : cond.valueModifier,
            operational_modifier: wellStateSelected // eslint-disable-line
              ? ''
              : cond.operationalModifier === 'VALUE'
              ? ''
              : cond.operationalModifier,
            value: wellStateSelected ? '' : cond.value,
            window: wellStateSelected ? '' : `${cond.howLong} ${cond.windowValue || 'hours'}`,
          },
          ...(cond.recurring &&
            wellStateSelected && {
              reoccurring_condition: {
                reoccurring: cond.value,
                duration: cond.howLong,
                rate: 'DAY',
              },
            }),
        });
      });

      const workflowToSave = {
        version: '1.0',
        well_source: workflow.result.source.length ? workflow.result.source : 'ALL',
        conditions,
        assign_to: {
          workflow_type: workflow.result.assignToType,
          [assignToKey]: workflow.result.to,
        },
      };
      await workflowApolloClient.mutate({
        mutation: updateWorkflowQuery,
        variables: {
          input: {
            id: workflow.id,
            username: accountModule.user.email.toLowerCase(),
            settings: JSON.stringify(workflowToSave),
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
      });
      await this.getWorkflows();
    } catch (e) {
      throw new Error(e);
    }
  }

  @Action
  async deleteWorkflow(workflow) {
    try {
      await workflowApolloClient.mutate({
        mutation: deleteWorkflow,
        variables: {
          input: {
            id: workflow.id,
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
      });
      this.deleteWorkflowMutation(workflow);
    } catch (e) {
      throw new Error(e);
    }
  }

  @Action

  async postShortcutBug({ title, description }) {
    try {
      const variables = {
        Title: title,
        Description: description,
        Operator: getConfigEnv('OPERATOR_LOWERCASED'),
      };
      const {
        data: {
          post_shortcut_bug: {},
        },
      }: any = await workflowApolloClient.mutate({
        mutation: postShortcutBugMutation,
        variables: {
          input: variables,
        },
      });
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }


  @Action
  async gettLinearIssuesLabels() {
    try {
      const variables = {
        operator: getConfigEnv('OPERATOR_LOWERCASED'),
      };
      const {
        data: {
          issueLabels: {
            nodes: nodeData

          },
        },
      }: any = await linearApolloClient.query({
        query: getLinearIssuesLabels,
        variables: variables,
      });
      return nodeData
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  @Action
  async gettLinearIssues() {
    try {
      const variables = {
        operator: `visible to ${getConfigEnv('OPERATOR_LOWERCASED')}`,
      };
      const {
        data: {
          issues: {
            nodes: nodeData

          },
        },
      }: any = await linearApolloClient.query({
        query: getLinearIssues,
        variables: variables,
        fetchPolicy: 'network-only',
      });
     return nodeData
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }


  @Action

  async postLinearBug({ title, description, labelID }) {
    try {
      const today = new Date(); // Gets today's date
      const threeDaysFromNow = new Date(today); // Creates a new Date object from today's date

      // Adds 3 days to the date
      threeDaysFromNow.setDate(today.getDate() + 3);

      console.log(threeDaysFromNow.toDateString()); // F
      const variables = {
        title: title,
        description: description,
        labelIds:labelID,
        dueDate: threeDaysFromNow.toDateString(),
        operator: getConfigEnv('OPERATOR_LOWERCASED'),
      };
      const {
        data: {
          issueCreate: {},
        },
      }: any = await linearApolloClient.mutate({
        mutation: postLinearBugMutation,
        variables: variables
      });
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }



  @Action
  async postUserNotificationSettings({ payload, workStartHour, workEndHour }: any) {
    try {
      const variables = {
        username: accountModule.user.email.toLowerCase(),
        operator: getConfigEnv('OPERATOR_LOWERCASED'),
        ...(payload && { notification_settings: JSON.stringify(payload), }),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        ...(workStartHour && { work_hour_start_time: workStartHour }),
        ...(workEndHour && { work_hour_end_time: workEndHour }),

      };
      const {
        data: {
          post_user_notification_settings: {},
        },
      }: any = await workflowApolloClient.mutate({
        mutation: postUserNotificationSettingsMutation,
        variables: {
          input: variables,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }


  @Action
  async postTodolist({ activeList,completedList, nodeID, level }: any) {
    try {
      const variables = {
        Operator: getConfigEnv('OPERATOR_LOWERCASED'),
        ActiveTodolist: JSON.stringify(activeList),
        CompletedTodolist: JSON.stringify(completedList),
        NodeID: nodeID,
        Level: level,


      };
      const {
        data: {
          post_todolist: {},
        },
      }: any = await workflowApolloClient.mutate({
        mutation: postTodolistMutation,
        variables: {
          input: variables,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }


  @Action
  async updateUserNotificationStatus(notificationID:any=null) {
    try {
      const variables = {
        ...(notificationID && { NotificationID: notificationID }),
        Operator: getConfigEnv('OPERATOR_LOWERCASED'),
      };
      const {
        data: {
          update_user_notification_status: {},
        },
      }: any = await workflowApolloClient.mutate({
        mutation: updateUserNotificationStatus,
        variables: {
          input: variables,
        },
      });
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  @Action
  async getUserAssignedToWell({ nodeId }) {
    try {
      const {
        data: { get_users_assigned_to_well: data },
      }: any = await workflowApolloClient.query({
        query: getUserAssignedToWell,
        variables: {
          input: {
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
            NodeID: nodeId,
          },
        },
        fetchPolicy: 'network-only',
      });

      if (data && data.Results && JSON.parse(data.Results)) {
        return JSON.parse(data.Results);
      }
      return {};
    } catch (e) {
      throw new Error(e);
    }
  }



  @Action
  async getUserNotifications(fromTime:any = null) {
    try {
      let time = DateTime.utc().toISO()
      .slice(0, -5);
      const {
        data: { get_user_notifications: data },
      }: any = await workflowApolloClient.query({
        query: getUserNotifications,
        variables: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          ...(fromTime && { FromTime: fromTime }),
          // fromTime: time,
        },
        fetchPolicy: 'network-only',
      });


      if(data && data.results && data.success){
        const notificationsData = (data.results)
        this.setUserNotifications({data: notificationsData, appendToExistingData:fromTime? true: false})
      }
    } catch (e) {
      throw new Error(e);
    }
  }

  @Action
  async getLatestDeferrmentValueForWell({ nodeIdList }) {
    try {
      const {
        data: { get_latest_deferrment_value_for_well: data },
      }: any = await workflowApolloClient.query({
        query: getLatestDeferrmentValueForWell,
        variables: {
          input: {
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
            NodeID: nodeIdList,
          },
        },
        fetchPolicy: 'network-only',
      });

      if (data && data.Results && JSON.parse(data.Results)) {
        return JSON.parse(data.Results)[0];
      }
      return {};
    } catch (e) {
      throw new Error(e);
    }
  }

  @Action
  async getWellStatuses(nodeID = null) {
    try {
      const {
        data: { get_well_statuses: data },
      }: any = await workflowApolloClient.query({
        query: getWellStatuses,
        variables: {
          input: {
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
            ...(nodeID && { NodeID: nodeID }),
          },
        },
        fetchPolicy: 'network-only',
      });

      if (data && data.Results) {
        if (!nodeID) {
          const results = data.Results.map((result) => {
            const status = JSON.parse(result);
            return {
              nodeID: status.NodeID,
              activeTodolistCount: status.ActiveTodolistCount,
              activeTodolistForUser: status.ActiveTodolistForUser
            };
          });


          this.setWellsStatus(results);
        }else{
          let snoozedData = null;
          if(data.Results.length){
            snoozedData = (JSON.parse(data.Results));
            const payload = {
              nodeID: nodeID,
              snoozed: snoozedData,
            }
            this.updateWellsStatus(payload)
          }

          return snoozedData
        }
      }
    } catch (e) {
     console.log(e);
    }
  }


  @Action
  async getWellTodolist(nodeID) {
    try {
      const {
        data: { get_well_todolist: data },
      }: any = await workflowApolloClient.query({
        query: getWellTodolist,
        variables: {
          input: {
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
            NodeID: nodeID,
          },
        },
        fetchPolicy: 'network-only',
      });

     if(data && data.Results){
      return JSON.parse(data.Results)
     }
     return []
    } catch (e) {
      throw new Error(e);
    }
  }
}

export default getModule(WorkflowModule);
