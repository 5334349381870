export default {
  'tasq-tooltip': {
    $extend: 'tooltip',
    delay: 0,
  },
  'tasq-dropdown': {
    $extend: 'dropdown',
    delay: 0,
  },
  'tasq-suggestion': {
    $extend: 'dropdown',
    delay: 0,
  },
  'clean-dropdown': {
    $extend: 'dropdown',
    delay: 0,
  }
}
