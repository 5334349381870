import './router/componentHooks';
import Vue from 'vue';
import installVue from '@/lib/installVue';
import installVueTailwind from '@/lib/installVueTailwind';
import '@/lib/directives/closable';
import VueTheMask from 'vue-the-mask';
import App from './App.vue';
import router from './router';
import store from './store';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import rules from '@/lib/validationRules';
import TasqDesktopFE from '@/components/base'
import mdiVue from 'mdi-vue/v2'
import mdiIcons from '@/lib/mdiIcons'
import FloatingVue from 'floating-vue'
import tooltipThemes from '@/lib/tooltipThemes'

Vue.config.ignoredElements = [/^cq-.*$/, /^stx-.*$/];

Vue.config.productionTip = false;
Vue.component('template-placeholder', {
	render: function(createElement) {
		return createElement('template', this.$slots.default)
	}
})
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
// Init Plugins

installVue(Vue);
Vue.use(TasqDesktopFE)
Vue.use(VueTheMask);
installVueTailwind(Vue);
Vue.use(mdiVue, { icons: mdiIcons});
Vue.use(FloatingVue, {themes: tooltipThemes});


new Vue({
  router,
  store,
  watch: {
    $route: () => {
      // @ts-ignore
      window.Appcues.page();
    },
  },
  render: (h) => h(App),
}).$mount('#app');
