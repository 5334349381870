/* eslint-disable */
const isTouch =
  typeof window !== 'undefined' && ('ontouchstart' in window || navigator.maxTouchPoints > 0);
const events = isTouch ? ['touchstart', 'click'] : ['click'];

const instances = [];

function processDirectiveArguments(bindingValue) {
  const isFunction = typeof bindingValue === 'function';
  if (!isFunction && typeof bindingValue !== 'object') {
    throw new Error('v-custom-click-outside: Binding value must be a function or an object');
  }

  return {
    handler: isFunction ? bindingValue : bindingValue.handler,
    middleware: bindingValue.middleware || (isClickOutside => isClickOutside),
    events: bindingValue.events || events,
    object: bindingValue.object || null,
  };
}

function onEvent({
                   el, event, handler, middleware, object
                 }) {
  const isClickOutside = event.target !== el && !el.contains(event.target);

  if (!isClickOutside) {
    return;
  }
  if (middleware(event, el)) {
    handler({event, el, object});
  }
}

function bind(el, { value }) {
  const { handler, middleware, events, object } = processDirectiveArguments(value);

  const instance = {
    el,
    eventHandlers: events.map(eventName => ({
      event: eventName,
      handler: event => onEvent({
        event, el, handler, middleware, object
      }),
    })),
  };

  instance.eventHandlers.forEach(({ event, handler }) => document.addEventListener(event, handler));
  // @ts-ignore
  instances.push(instance);
}

function update(el, { value }) {
  const { handler, middleware, events, object } = processDirectiveArguments(value);
  // @ts-ignore
  const instance = instances.find(instance => instance.el === el);

  // @ts-ignore
  instance.eventHandlers.forEach(({ event, handler }) =>
    document.removeEventListener(event, handler));
  // @ts-ignore
  instance.eventHandlers = events.map(eventName => ({
    event: eventName,
    handler: event => onEvent({
      event, el, handler, middleware, object
    }),
  }));

  // @ts-ignore
  instance.eventHandlers.forEach(({ event, handler }) => document.addEventListener(event, handler));
}

function unbind(el) {
  // @ts-ignore
  const instance = instances.find(instance => instance.el === el);
  // @ts-ignore
  instance.eventHandlers.forEach(({ event, handler }) =>
    document.removeEventListener(event, handler));
}

const clickOutside = {
  bind,
  update,
  unbind,
  instances,
};

export default clickOutside;
