
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TasqTabNav extends Vue {
  @Prop({ type: Array }) tabs!: any[];

  @Prop({ type: Function }) handleTabClick!: any;

  @Prop({ type: Boolean, default: false }) tabsAsTags!: boolean;

  @Prop({ type: Boolean, default: false }) tabsAsBorder!: boolean;

  @Prop({ type: String, default: 'large' }) size!: string;

  setActiveTab() {
    this.$forceUpdate();
  }

  get tailwindClassForHeight() {
    const sizeMap = {
      small: 'h-10',
      medium: 'h-12',
      large: 'h-16',
    };
    return sizeMap[this.size];
  }
}
