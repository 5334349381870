import TasqButton from './TasqButton.vue'
import TasqAccordion from './TasqAccordion.vue'
import TasqTabs from './TasqTabs/Tabs.vue'
import TasqTabPane from './TasqTabs/TabPane.vue'
import TasqAlert from './TasqAlert.vue'
import TasqChip from './TasqChip.vue'
import TasqTag from './TasqTag.vue'

const TasqDesktopFE = {
  install (Vue) {
    Vue.component('tasq-button', TasqButton)
    Vue.component('tasq-tabs', TasqTabs)
    Vue.component('tasq-tab-pane', TasqTabPane)
    Vue.component('tasq-accordion', TasqAccordion)
    Vue.component('tasq-alert', TasqAlert)
    Vue.component('tasq-chip', TasqChip)
    Vue.component('tasq-tag', TasqTag)
  }
}

export default TasqDesktopFE
