import { RouteConfig } from 'vue-router';

const files = require.context('.', true, /\.ts$/);
const routes: Array<RouteConfig> = [];

files.keys().forEach((key: string) => {
  if (key === './index.ts') return;
  routes.push(files(key).default);
});

export default routes;
