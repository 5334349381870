import VueApollo from 'vue-apollo';
import ApolloClient from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';

// Setup Apollo client to work with Linear API
const httpLink = new HttpLink({ uri: 'https://api.linear.app/graphql' });

const authLink = setContext(async () => {
  return {
    headers: {
      Authorization: `lin_api_v691Bvp4xI9yFHgelC33KK2KMKdNofT0mZUnO46b`,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const apolloProvider = new VueApollo({ defaultClient: client });

export default apolloProvider.defaultClient;
