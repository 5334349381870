import {Vue} from 'vue-property-decorator';
import TasqAlert from './TasqAlert.vue';
import {TasqAlertState} from './TasqAlert.vue';

const AlertConstructor = Vue.extend(TasqAlert);
let seed: number = 1;
let zIndex: number = 2000;

export default function TasqAlertGenerator(userOptions) {
  let options = userOptions || {};
  if (typeof options === 'string') {
    options = {
      message: options,
    };
  }
  let instance = TasqAlertState.instance
  if (instance) {
    instance = Object.assign(instance, options);
    instance.restartTimer();
  } else {
    instance = new AlertConstructor({
      data: options,
    });
    instance.id = options.key || `message_${seed++} ${new Date().getTime()}`;
    instance.vm = instance.$mount();
    document.body.appendChild(instance.vm.$el);
    instance.vm.visible = true;
    instance.dom = instance.vm.$el;
    instance.dom.style.zIndex = zIndex++;
    TasqAlertState.instance = instance;
  }
  return instance.vm;
}
