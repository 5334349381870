// @ts-ignore
import { rejectAuth } from '@/lib/auth';
import { getView } from '@/utils/helpers';

export default {
  component: () => getView('auth/SignUp'),
  beforeEnter: rejectAuth,
  path: '/signup',
  name: 'SignUp',
  meta: {
    skipAuth: true,
    bodyClass: ['bg-blue', 'without-footer'],
  },
};
