import VueTailwind from 'vue-tailwind';
import { VueConstructor } from 'vue';
import {
  TButton,
  TDatepicker,
  TToggle,
  TInput,
  TTextarea,
  TRadio,
  TRadioGroup,
  TCheckbox,
  TTag,
  TCard,
  TCheckboxGroup,
  TSelect,
  TDialog,
  TDropdown,
  TRichSelect,
  TAlert,
} from 'vue-tailwind/dist/components';

const components = {
  TButton: {
    component: TButton,
    props: {
      variants: {
        primary: ' text-black005 bg-tasqBase100 hover:bg-blue014 disabled:bg-blue014 disabled:opacity-30',
        primaryGhost: 'border-tasqBase100 text-tasqBase100 bg-transparent hover:text-blue014 disabled:border-tasqNeutral200 disabled:bg-tasqNeutral200 disabled:text-tasqNeutral300',
        secondary: ' text-tasqNeutral100 bg-secondary200 hover:bg-secondary100 hover:text-tasqNeutral800 disabled:border-tasqNeutral200 disabled:bg-tasqNeutral200 disabled:text-tasqNeutral300',
        secondaryGhost: 'border-secondary100 text-secondary100 bg-tasqNeutral800 hover:bg-tasqNeutral600 disabled:border-tasqNeutral200 disabled:bg-tasqNeutral200 disabled:text-tasqNeutral300',
        tertiary: ' text-tasqNeutral100 bg-tasqNeutral1900 hover:bg-tasqNeutral1900 hover:border border-pimary500 disabled:bg-tasqNeutral200 disabled:text-tasqNeutral300',
        tertiaryGhost: 'border-tasqNeutral100 text-tasqNeutral100 bg-tasqNeutral800 hover:bg-tasqNeutral700 disabled:border-tasqNeutral200 disabled:bg-tasqNeutral200 disabled:text-tasqNeutral300',
        quinary: 'bg-blue003 text-gray002 hover:bg-blue006 disabled:bg-blue003',
        text: 'bg-transparent text-tasqBase100',
      }
    }
  },
  TDatepicker,
  TInput: {
    component: TInput,
    props: {
      classes: 'border-opacity-20 text-white block w-full px-3 py-2 placeholder-gray-400 transition duration-100 ease-in-out bg-transparent border border-gray-300 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
      variants: {
        primary: 'w-full bg-transparent p-2.5 pt-2 rounded border-white border-opacity-20',
        secondary: 'bg-tasqNeutral1200 p-2.5 pt-2 rounded border-gray005 placeholder-tasqNeutral200',
      }
    },
  },
  TToggle: {
    component: TToggle,

    props: {
      classes:{
        wrapper: 'bg-tasqNeutral600 rounded-full border-2 border-transparent focus:ring-2 focus:ring-tasqNeutral600 focus:outline-none focus:ring-opacity-50',
        wrapperChecked: 'bg-primary500 rounded-full border-2 border-transparent focus:ring-2 focus:ring-primary500 focus:outline-none focus:ring-opacity-50',
        wrapperDisabled: 'bg-tasqNeutral600 rounded-full border-2 border-transparent focus:ring-2 focus:ring-tasqNeutral600 focus:outline-none focus:ring-opacity-50',
        wrapperCheckedDisabled: 'bg-blue-500 rounded-full border-2 border-transparent focus:ring-2 focus:ring-tasqNeutral600 focus:outline-none focus:ring-opacity-50',
        button: 'h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-gray-400 text-xs',
        buttonChecked: 'h-5 w-5 rounded-full bg-tasqNeutral1000 shadow flex items-center justify-center text-blue-500 text-xs',
        checkedPlaceholder: 'rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs',
        uncheckedPlaceholder: 'rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs',
      },
        variants: {
        primary: 'w-full bg-primary500 p-2.5 pt-2 rounded border-white border-opacity-20',
        secondary: 'bg-primary500 p-2.5 pt-2 rounded border-gray005 placeholder-tasqNeutral200',
      }
    },
  },
  TTextarea: {
    component: TTextarea,
    props: {
      variants: {
        primaryGhost: 'w-full bg-transparent p-2.5 pt-2 rounded-sm border-none text-white outline-none',
        primary: 'w-full bg-transparent p-2.5 pt-2 rounded-sm border-white text-white border-opacity-20',
      }
    }
  },
  TDropdown,
  TCard: {
    component: TCard,
    props: {
      fixedClasses: {
        wrapper: 'rounded shadow-sm',
        body: 'p-3 rounded shadow-sm border-solid border border-white  border-opacity-10',
        header: 'border-b p-3 rounded-t',
        footer: 'border-t p-3 rounded-b'
      },
      classes: {
        wrapper: 'bg-gray-700 ',
        body: 'rounded shadow-sm border-solid border border-white  border-opacity-10 text-white ',
        header: 'border-gray-100',
        footer: 'border-gray-100'
      },
      variants: {
        danger: {
          wrapper: 'bg-red-50 text-red-700 border-red-200',
          header: 'border-red-200 text-red-700',
          footer: 'border-red-200 text-red-700'
        }
      }
    }
  },
  TRadio,
  TTag: {
    component: TTag,
    props: {
      variants: {
        success: 'inline-flex items-center px-5 rounded-full text-xs py-1 font-medium leading-4 bg-tasqCompleteBg text-tasqCompleteText',
        info: 'inline-flex items-center px-3 rounded-full text-xs py-1 font-medium leading-4 bg-tasqInProgressBg text-tasqInProgressText',
        warning: 'inline-flex items-center px-3 rounded-full text-xs py-1 font-medium leading-4 bg-tasqOpenBg text-tasqOpenText',
        error: 'inline-flex items-center px-3 rounded-full text-xs py-1 font-medium leading-4 bg-tasqBlockedBg text-tasqBlockedText'
      },
    },
  },
  TRadioGroup: {
    component: TRadioGroup,
    props: {
      classes: {
        wrapper: 'grid grid-flow-col mb-2 text-sm justify-start ',
        input: 'mr-2 bordered-radio text-green-500',
      },
      variants: {
        error: {
          input: 'text-red-500 transition duration-100 ease-in-out',
          label: 'bg-red-100 text-red-500',
        },
      },
    },
  },
  TCheckbox:  {
    component: TCheckbox,
    props: {
      classes: {
        wrapper: 'grid grid-flow-col justify-start mb-2',
        input: 'mr-2 tasq-checkbox text-green-500',
      },
      variants: {
        error: {
          input: 'text-red-500 transition duration-100 ease-in-out',
          label: 'bg-red-100 text-red-500',
        },
      },
    }
  },
  TCheckboxGroup: {
    component: TCheckboxGroup,
    props: {
      classes: {
        wrapper: 'grid grid-flow-col justify-start mb-2',
        input: 'mr-2 tasq-checkbox text-green-500',
      },
      variants: {
        error: {
          input: 'text-red-500 transition duration-100 ease-in-out',
          label: 'bg-red-100 text-red-500',
        },
      },
    },
  },
  TSelect,
  TDialog,
  TRichSelect,
  TAlert: {
    component: TAlert,
    props: {
      fixedClasses: {
        wrapper: 'fixed flex items-center p-4 border-l-4  rounded shadow-sm',
        body: 'flex-grow',
        close: 'absolute relative flex items-center justify-center ml-4 flex-shrink-0 w-6 h-6 transition duration-100 ease-in-out rounded  focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
        closeIcon: 'fill-current h-4 w-4',
      },
      classes: {
        wrapper: 'bg-white border-cyan',
        body: 'text-cyan',
        close: 'text-charcoal',
      },
    },
  },
};

export default function installVueTailwind(Vue: VueConstructor) {
  Vue.use(VueTailwind, components);
}
