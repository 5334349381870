import { extend } from 'vee-validate';
import { required, alpha_num, email } from 'vee-validate/dist/rules';
import { validatePhoneNumberLength, isValidPhoneNumber } from 'libphonenumber-js';

const rules = {
  'email': {
    ...email,
    message: 'The {_field_} field is invalid.'
  },
  'min-max': {
    validate(value: string, { min, max }: any) {
      return value.length >= min && value.length <= max;
    },
    params: ['min', 'max'],
    message: 'The {_field_} field must have at least {min} characters and {max} characters at most.'
  },
  'alpha-num': {
    ...alpha_num,
    message: 'The {_field_} field must have alpha numeric characters only.'
  },
  'required': {
    ...required,
    message: 'The {_field_} field is required.'
  },
  'phonenumber': {
    validate(value) {
      return isValidPhoneNumber(value);
    },
    message: 'The {_field_} field must be a valid phone number.'
  }
}

export default rules
