import { getView } from '@/utils/helpers';

export default {
  component: () => getView('Report'),
  path: '/report/:daysAgo?',
  name: 'Report',
  meta: {
    title: 'Tasqinc - Report',
    allowedForAll: true,
  },
};
