// @ts-ignore
import { isNotVerified } from '@/lib/auth';
import { getView } from '@/utils/helpers';

export default {
  component: () => getView('auth/VerifyInfo'),
  beforeEnter: isNotVerified,
 path: '/verify',
  name: 'VerifyInfo',
  meta: {
    skipAuth: true,
    bodyClass: ['bg-blue', 'without-footer'],
  },
};
