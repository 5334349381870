import accountModule from '@/store/modules/accountModule';

const getNameByEmail = (email) => accountModule.reassignmentList?.find(
  (user) => user.email.toLowerCase() === email.toLowerCase(),
)?.name || email;

export {
  // eslint-disable-next-line import/prefer-default-export
  getNameByEmail,
};
